<template>
  <div
    fluid
    :style="{
      backgroundImage:
        'url(' + require('@/assets/images/' + background_desktop) + ')',
      height: '100vh',
      width: '100vw',
      display: 'flex',
      flexDirection: 'column',
      overflowY: 'hidden',
      backgroundSize: 'cover',
    }"
    class="background-container d-flex align-center justify-center flex-column"
  >
    <v-sheet
      class="h-auto d-flex flex-column align-end"
      :style="{ backgroundColor: 'transparent' }"
    >
      <v-sheet
        style="width: 800px"
        class="d-flex flex-column justify-start align-start"
        :style="{ backgroundColor: 'transparent' }"
      >
        <v-img class="mb-5" :height="50" contain :src="logoSrc()"> </v-img>
      </v-sheet>
    </v-sheet>
    <v-sheet class="h-auto d-flex" :style="{ backgroundColor: 'transparent' }">
      <v-sheet
        class="d-flex flex-row"
        :style="{ backgroundColor: 'transparent', width: '800px' }"
      >
        <v-sheet
          class="d-flex flex-row justify-center align-center pa-5"
          :style="{ backgroundColor: color2, height: '250px', width: '515px' }"
        >
          <v-sheet :style="{ backgroundColor: 'transparent' }">
            <p
              :style="{
                fontFamily: fontFamily,
                fontSize: 40,
                fontWeight: 'bold',
                color: color1,
              }"
            >
              MA PHOTO D'IDENTITE
            </p>
            <p
              :style="{
                fontFamily: fontFamily,
                fontSize: 15,
                fontWeight: 'bold',
                color: color1,
              }"
            >
              Scanne le QR code pour faire ta photo d’identité avec ton
              smartphone.
            </p>
          </v-sheet>
          <v-sheet
            :style="{
              backgroundColor: 'transparent',
              height: '148px',
              width: '164px',
            }"
            ><v-img class="mb-5" :height="148" contain :src="imageWallet()">
            </v-img>
          </v-sheet>
        </v-sheet>
        <div
          class="bg-surface-variant rounded-xl mx-auto"
          style="height: 250px; width: 250px"
        >
          <v-img :src="qrCodeSrc"></v-img>
        </div>
      </v-sheet>
    </v-sheet>
    <v-sheet class="h-auto" :style="{ backgroundColor: 'red' }"></v-sheet>
  </div>
</template>

<script>
import { localStorageService } from '@/services/localStorage.service'

export default {
  name: 'ScanQrcode',
  data() {
    return {
      qrCodeSrc:
        'data:image/jpeg;base64,' +
        localStorageService.getValue('qrCodeBase64'),
      background_desktop: localStorageService.getStyle(
        location.hostname,
        'WEBAPPPRO_BACKGROUND_DESKTOP',
      ),
      color1: localStorageService.getStyle(
        location.hostname,
        'WEBAPPPRO_COLOR_1',
      ),
      color2: localStorageService.getStyle(
        location.hostname,
        'WEBAPPPRO_COLOR_2',
      ),
      fontFamily: localStorageService.getStyle(
        location.hostname,
        'WEBAPPPRO_FONT_FAMILY',
      ),
      textSize1: localStorageService.getStyle(
        location.hostname,
        'WEBAPPPRO_FONT_SIZE_1',
      ),
      textSize2: localStorageService.getStyle(
        location.hostname,
        'WEBAPPPRO_FONT_SIZE_2',
      ),
    }
  },
  methods: {
    logoSrc() {
      const f = localStorageService.getStyle(
        location.hostname,
        'WEBAPPPRO_LOGO_2',
      )
      return require(`../assets/images/${f}`)
    },
    imageWallet() {
      const f = localStorageService.getStyle(
        location.hostname,
        'WEBAPPPRO_ICON_QRCODE_SCREEN',
      )
      return require(`../assets/images/${f}`)
    },
  },
}
</script>
