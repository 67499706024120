var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"background-container d-flex align-center justify-center flex-column",style:({
    backgroundImage:
      'url(' + require('@/assets/images/' + _vm.background_desktop) + ')',
    height: '100vh',
    width: '100vw',
    display: 'flex',
    flexDirection: 'column',
    overflowY: 'hidden',
    backgroundSize: 'cover',
  }),attrs:{"fluid":""}},[_c('v-sheet',{staticClass:"h-auto d-flex flex-column align-end",style:({ backgroundColor: 'transparent' })},[_c('v-sheet',{staticClass:"d-flex flex-column justify-start align-start",staticStyle:{"width":"800px"},style:({ backgroundColor: 'transparent' })},[_c('v-img',{staticClass:"mb-5",attrs:{"height":50,"contain":"","src":_vm.logoSrc()}})],1)],1),_c('v-sheet',{staticClass:"h-auto d-flex",style:({ backgroundColor: 'transparent' })},[_c('v-sheet',{staticClass:"d-flex flex-row",style:({ backgroundColor: 'transparent', width: '800px' })},[_c('v-sheet',{staticClass:"d-flex flex-row justify-center align-center pa-5",style:({ backgroundColor: _vm.color2, height: '250px', width: '515px' })},[_c('v-sheet',{style:({ backgroundColor: 'transparent' })},[_c('p',{style:({
              fontFamily: _vm.fontFamily,
              fontSize: 40,
              fontWeight: 'bold',
              color: _vm.color1,
            })},[_vm._v(" MA PHOTO D'IDENTITE ")]),_c('p',{style:({
              fontFamily: _vm.fontFamily,
              fontSize: 15,
              fontWeight: 'bold',
              color: _vm.color1,
            })},[_vm._v(" Scanne le QR code pour faire ta photo d’identité avec ton smartphone. ")])]),_c('v-sheet',{style:({
            backgroundColor: 'transparent',
            height: '148px',
            width: '164px',
          })},[_c('v-img',{staticClass:"mb-5",attrs:{"height":148,"contain":"","src":_vm.imageWallet()}})],1)],1),_c('div',{staticClass:"bg-surface-variant rounded-xl mx-auto",staticStyle:{"height":"250px","width":"250px"}},[_c('v-img',{attrs:{"src":_vm.qrCodeSrc}})],1)],1)],1),_c('v-sheet',{staticClass:"h-auto",style:({ backgroundColor: 'red' })})],1)}
var staticRenderFns = []

export { render, staticRenderFns }